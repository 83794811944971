import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import * as React from "react";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import { DefaultButton, Layout, ProjectsSlider, Seo } from "../components";
import useWindowSize from "../hooks/useWindowSize";
import aboutUs from "../images/about-us-bg.png";
import HeaderImage from "../images/background.png";
import backgroundJmf from "../images/backgroundjmf.png";
import backgroundBox from "../images/premiere-steel-erectors-bg.png";
import Logo from "../images/svg/logo";

import "../styles/screen.scss";

const seoBundle = {
  title: "Boise Steel Erectors",
  subtitle: "About JMF Steel Erectors",
  description: "Boise Steel Erector company",
  slug: "/",
};

const IndexPage = ({ location }) => {
  const { firstImage, secondImage } = useStaticQuery(
    graphql`
      query {
        firstImage: file(relativePath: { eq: "08.png" }) {
          childImageSharp {
            gatsbyImageData(
              height: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        secondImage: file(relativePath: { eq: "07.png" }) {
          childImageSharp {
            gatsbyImageData(
              height: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image4 = getImage(firstImage);
  const image5 = getImage(secondImage);

  // Use like this:
  const bgImage4 = convertToBgImage(image4);
  const bgImage5 = convertToBgImage(image5);
  const { width } = useWindowSize();

  const getPerPage = () => {
    if (width > 950) {
      return 2;
    } else {
      return 1;
    }
  };
  return (
    <Layout title={"Home"} main location={location}>
      <Seo seoBundle={seoBundle} />
      <Heading as='h1' display='none'>
        JMF Construction
      </Heading>
      <Box position='relative'>
        <Image pt='83px' w='100%' src={`${HeaderImage}`} alt='Dan Abramov' />
        <Box className='banner-title'>
          <Heading
            as='h2'
            fontSize={{ base: "24px", md: "50px", lg: "60px" }}
            color='#3B3B3B'
            textAlign='center'
            margin='auto'
          >
            Steel Construction
          </Heading>
          <Heading
            marginTop='20px'
            fontWeight={400}
            fontSize={{ base: "18px", md: "30px", lg: "45px" }}
            color='#3B3B3B'
            textAlign='center'
            margin='auto'
          >
            Backed By Experience
          </Heading>

          <Box
            display={{ base: "block", md: "none" }}
            textAlign='center'
            py='12px'
          >
            <DefaultButton title='Call Now' />
          </Box>
        </Box>
      </Box>

      <Box>
        <ProjectsSlider />
      </Box>

      {/* jmf */}
      <Box
        className='bg-md-none'
        display={["block"]}
        backgroundImage={`url(${backgroundJmf})`}
        backgroundSize='cover'
        backgroundPosition='bottom'
        backgroundRepeat='no-repeat'
        maxW='full'
      >
        <Flex
          h='100%'
          align='center'
          justify='center'
          maxW='800px'
          mx='auto'
          py={{ base: "50px", md: "100px" }}
        >
          <Box>
            <VStack align='center'>
              <Box w='280px'>
                <Logo width='100%' height='100px' fill='#3b3b3b' />
              </Box>
              <Text
                as='p'
                color='dkGrey'
                fontWeight='bold'
                fontSize='5xl'
                pt='35'
                pb='0px'
                mb='0px'
                textAlign='center'
              >
                CALL TODAY
              </Text>
              <Box
                as='p'
                fontWeight='500'
                fontSize='5xl'
                fontFamily='heading'
                lineHeight='120%'
                textAlign='center'
              >
                <Link to={`tel:2088801062`}>(208) 880-1062</Link>
              </Box>
              {/* <Text
                as="p"
                color="dkGrey"
                fontWeight="bold"
                fontSize="3xl"
                textAlign="center"
                pb="20"
              >
                WE GIVE FREE ESTIMATES!
              </Text> */}
            </VStack>
          </Box>
        </Flex>
      </Box>

      {/* new section  */}
      <Box
        className='bg-md-none'
        w='100%'
        py={{ base: "50px", md: "120px" }}
        px={{ base: "50px", md: "120px" }}
        display={["block"]}
        backgroundColor='#EECA83'
        backgroundImage={`url(${backgroundBox})`}
        backgroundSize='contain'
        backgroundPosition='bottom'
        backgroundRepeat='no-repeat'
      >
        <Box marginTop='10px' textAlign='center'>
          <Heading
            as='h1'
            color='dkGrey'
            fontWeight={800}
            fontSize={["1.5em", "3em"]}
            fontFamily={"heading"}
            lineHeight='100%'
          >
            Premiere Steel Erectors
          </Heading>
          <Text
            margin={"40px auto"}
            fontSize='2xl'
            color='dkGrey'
            maxW='1024'
            pb='10'
            className='cap'
            fontFamily={"body"}
          >
            JMF specializes in pre-engineered steel structures for commercial,
            agricultural, and residential uses. Handling projects of all sizes,
            chances are that we not only can build your structure, but we have
            built many like it already. JMF puts up quality buildings, aims for
            excellence in craftsmanship, and just does things the right way in
            general. With literal decades of experience, it is rare for much to
            surprise us. We’ve done projects all over the Treasure Valley and
            beyond for businesses such as Simplot Western Stockmen’s, CSDI,
            Champion Produce in Parma, and Farm Equipment USA in Fruitland and
            Jerome. Have a small project? You’ve come to the right place. We
            build smaller projects regularly, not just the large scale ones.
            Head over to our Frequently Asked Questions section to learn more
            about the process. If you still have questions, please call us. We
            truly want to be as helpful as possible.
          </Text>
          <Box display={{ base: "block", md: "none" }}>
            <DefaultButton title='See Project' />
          </Box>
        </Box>
      </Box>

      {/* <BackgroundImage
        Tag='section'
        {...bgImage4}
        preserveStackingContext
        backgroundColor='#ABC8D8'
      >
        <div
          style={{
            minWidth: "100%",
            minHeight: 800,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <VStack>
            <Heading as='h2' color='dkGrey' fontWeight={600} fontSize='5rem'>
              Testimonials
            </Heading>
            <Heading as='h5' color='dkGrey'>
              Our awesome customer feedback
            </Heading>
          </VStack>

          <Splide
            aria-label='Our customer testimonials'
            className='testimonial-slider'
            options={{
              autoplay: true,
              type: "loop",
              width: getPerPage() > 1 ? 1050 : 450,
              gap: "2rem",
              perPage: getPerPage(),
            }}
          >
            <SplideSlide>
              <Flex
                display='flex'
                flexDir='column'
                bg='#fff'
                justify='center'
                align='center'
                h='300px'
                w={["100%", null, null, "400px"]}
                px={["8px", null, null, "40px"]}
                borderRadius='20px'
              >
                <Text
                  fontWeight={600}
                  fontFamily='heading'
                  textAlign='center'
                  fontSize={"16px"}
                  pb='20px'
                >
                  "Fantastic company with awesome pricing. They did everything
                  they said and did some extra work as well. The clean up after
                  the job was better than my own work. Thank you guys again!"
                </Text>
                <Text color='#a2a2a2' fontFamily='heading'>
                  Stuart S.
                </Text>
              </Flex>
            </SplideSlide>
            <SplideSlide>
              <Flex
                display='flex'
                flexDir='column'
                bg='#fff'
                justify='center'
                align='center'
                h='300px'
                w={["100%", null, null, "400px"]}
                px={["8px", null, null, "40px"]}
                borderRadius='20px'
              >
                <Text
                  fontWeight={600}
                  fontSize={"16px"}
                  fontFamily='heading'
                  textAlign='center'
                  pb='20px'
                >
                  "What a great crew to work with! They worked so quickly and
                  were done by 6:30 the first day!"
                </Text>
                <Text color='#a2a2a2' fontFamily='heading'>
                  Lauren K.
                </Text>
              </Flex>
            </SplideSlide>
            <SplideSlide>
              <Flex
                display='flex'
                flexDir='column'
                bg='#fff'
                justify='center'
                align='center'
                h='300px'
                w={["100%", null, null, "400px"]}
                px={["8px", null, null, "40px"]}
                borderRadius='20px'
              >
                <Text
                  fontWeight={600}
                  fontSize={"16px"}
                  fontFamily='heading'
                  textAlign='center'
                  pb='20px'
                >
                  "Wow! Just wow! The roof is incredible! I did not expect the
                  crew to work so quickly and to be so clean about the whole
                  process. I left for work, and by the time I was back the house
                  looked new!"
                </Text>
                <Text color='#a2a2a2' fontFamily='heading'>
                  Johnathon H.
                </Text>
              </Flex>
            </SplideSlide>
          </Splide>
        </div>
      </BackgroundImage> */}

      <Box
        className='bg-md-none'
        m={["auto"]}
        display={["block"]}
        backgroundImage={`url(${aboutUs})`}
        backgroundSize='contain'
        backgroundPosition='bottom'
        backgroundRepeat='no-repeat'
        w='full'
      >
        <Box
          w='full'
          maxW='800px'
          m='auto'
          py='91px'
          px={{ base: "10px", md: "0px" }}
        >
          <Box textAlign='center'>
            <Heading
              as='h2'
              fontFamily='body'
              color='dkGray'
              fontWeight={800}
              fontSize='5rem'
            >
              About Us
            </Heading>
            <Heading as='h3' fontFamily='body' pb='2rem'>
              Serving Southern Idaho & Surrounding Areas
            </Heading>
          </Box>
          <Grid alignItems='center' templateColumns='repeat(12, 1fr)' gap={4}>
            <GridItem order={{ base: 2, md: 1 }} colSpan={{ base: 12, md: 4 }}>
              <Box
                m='auto'
                display='flex'
                h={["150px"]}
                w={["150px"]}
                justifyContent='center'
              >
                <StaticImage
                  m='auto'
                  w='full'
                  src='../images/jake.jpg'
                  alt='birds-eye-view of construction crew'
                  placeholder='blurred'
                  layout='constrained'
                  style={{ borderRadius: "50%" }}
                />
              </Box>
              <Text
                textAlign='center'
                m='auto'
                pt='20px'
                fontFamily='heading'
                fontWeight={600}
              >
                Jake Fillmore
              </Text>
              <Text textAlign='center' m='auto' fontSize='12px'>
                CEO, Owner & Operator
              </Text>
            </GridItem>
            <GridItem order={{ base: 1, md: 2 }} colSpan={{ base: 12, md: 8 }}>
              <Text
                fontFamily='body'
                textAlign={{ base: "center", md: "left" }}
              >
                Born and raised in Idaho, Jake Fillmore first began building
                steel structures in high school for his father’s company, then
                called Fillmore Custom Builders. The company’s work ranged from
                dairies and barns, airplane hangars, shops, and storage
                facilities to custom homes, remodels and everything in between.
                Jake took the company over when his father retired. With over 30
                years of experience, JMF has become very specialized in steel
                construction of all project sizes.
              </Text>
            </GridItem>
            <GridItem order={{ base: 1, md: 3 }} colSpan={{ base: 12, md: 12 }}>
              <Heading
                py={{ base: "25px", md: "50px" }}
                as='h3'
                textAlign='center'
                fontFamily='body'
              >
                Premiere steel construction backed by over 30 years of
                experience
              </Heading>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};

export default IndexPage;
